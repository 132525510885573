import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  SignUpModel,
  LoginModel,
  ForgotPasswordModel,
  ResetPasswordModel,
  ChangePasswordModel,
} from '../models/auth.model';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public BACKEND_HOST = environment.url;

  constructor(private http: HttpClient) {}

  // register
  register(data: SignUpModel): any {
    return this.http
      .post<SignUpModel>(`${this.BACKEND_HOST}/user/register`, data)
      .pipe(
        map((res: any) => {
          return res.data;
        }),
      );
  }

  // log in
  login(credential: LoginModel): any {
    return this.http.post(`${this.BACKEND_HOST}/auth/login`, credential).pipe(
      map((res: any) => {
        return res.data;
      }),
    );
  }

  // log in
  verifiyLogin(credential: LoginModel): any {
    return this.http
      .post(`${this.BACKEND_HOST}/auth/login-verification`, credential)
      .pipe(
        map((res: any) => {
          res = res.data;
          console.log(res);
          if (!res.user.hasCompletedSignup && res.user.role === 'user') {
            if (res.token) {
              sessionStorage.setItem('token', res.token);
              sessionStorage.setItem('role', res.user.role);
            }
            if (res.user) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              sessionStorage.setItem('user', JSON.stringify(res.user));
            }
            // this.currentUserSubject.next(res.user);
          }
          return res;
        }),
      );
  }

  // async isAdminCheck(): Promise<boolean> {
  //   console.log(this.currentUserSubject.value);
  //   await this.currentUser.subscribe(x => {
  //     ['admin', 'care-member', 'developer'].includes(x.role)
  //   });

  //   return this.isLoggedIn() && ['admin', 'care-member', 'developer'].includes(this.currentUserSubject.value.role);
  // }

  // log out
  logout(): any {
    const accessToken = sessionStorage.getItem('token');
    if (accessToken) {
      sessionStorage.clear();
      return this.http
        .get(`${this.BACKEND_HOST}/auth/logout`, {
          headers: new HttpHeaders().set('X-Auth-Token', `${accessToken}`),
        })
        .pipe(
          map((res: any) => {
            return res.data;
          }),
        );
    }
  }

  // ResetPassword
  forgetPassword(credential: ForgotPasswordModel): any {
    return this.http
      .post<ResetPasswordModel>(
        `${this.BACKEND_HOST}/auth/forget-password`,
        credential,
      )
      .pipe(
        map((res: any) => {
          return res.data;
        }),
      );
  }

  // verifiyResetPasswordCode
  verifiyResetPasswordCode(credential: ResetPasswordModel): any {
    return this.http
      .post<ResetPasswordModel>(
        `${this.BACKEND_HOST}/auth/verify-password-reset-code`,
        credential,
      )
      .pipe(
        map((res: any) => {
          return res.data;
        }),
      );
  }

  // resetPassword
  resetPassword(credential: ResetPasswordModel): any {
    return this.http
      .post<ResetPasswordModel>(
        `${this.BACKEND_HOST}/auth/reset-password`,
        credential,
      )
      .pipe(
        map((res: any) => {
          return res.data;
        }),
      );
  }

  // changePassword
  changePassword(credential: ChangePasswordModel): any {
    return this.http
      .put<ChangePasswordModel>(
        `${this.BACKEND_HOST}/auth/change-password`,
        credential,
      )
      .pipe(
        map((res: any) => {
          return res.data;
        }),
      );
  }

  isLoggedIn(): boolean {
    const adminRoles = ['site-member', 'sponsor','admin'];
    const isUser = !adminRoles.includes(sessionStorage.getItem('role') || '');
    return !!sessionStorage.getItem('token') && isUser;
  }

  isLoggedInGeneral(): boolean {
    return !!sessionStorage.getItem('token');
  }
}
